import { useEffect, useState } from 'react'
import { Link, useLocation } from 'react-router-dom'

function NavItem({ label, path, icon }) {
  const [active, setActive] = useState(false)
  const { pathname } = useLocation()

  const sa_bg_color = 'white'
  const sa_text_color = '#0a4d85'
  const s_text_color = 'white'

  useEffect(() => {
    setActive(pathname == path)
  }, [path, pathname])

  return (
    <li>
      <Link
        to={path}
        onMouseEnter={(e) =>
          !active &&
          e.currentTarget.setAttribute(
            'style',
            `background-color: ${sa_bg_color}; color: ${sa_text_color}`
          )
        }
        onMouseLeave={(e) =>
          !active &&
          e.currentTarget.setAttribute(
            'style',
            `background-color: ''; color: ${s_text_color}`
          )
        }
        style={{
          backgroundColor: active ? sa_bg_color : '',
          color: active ? sa_text_color : s_text_color,
        }}
        className={`flex items-center p-2  rounded-lg 
        ${active ? (sa_bg_color ? '' : 'bg-gray-100 ') : 'text-gray-500'}
        ${active ? (sa_text_color ? '' : ' text-gray-900') : 'text-gray-500'}
        ${sa_bg_color ? '' : 'hover:bg-gray-100'} group`}
      >
        {icon}
        <span className='ms-3'>{label}</span>
      </Link>
    </li>
  )
}

export default NavItem
