import { ErrorMessage, useFormikContext } from 'formik'
import React from 'react'

function InputFieldOutlined({
  label,
  name,
  placeholder,
  type,
  readOnly,
  required,
  StartIcon,
  EndIcon,
  value,
  onChange,
  autoFocus,
  Ref,
}) {
  const formik = useFormikContext()
  return (
    <div>
      <label for={name} class='block mb-2 text-sm font-medium text-gray-900 '>
        {label}
        {required ? ' *' : ''}
      </label>
      <div className='relative w-full'>
        {StartIcon && (
          <div class='absolute inset-y-0 start-0 flex items-center ps-3 pointer-events-none'>
            {StartIcon}
          </div>
        )}
        <input
          ref={Ref}
          id={name}
          class={`bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full ${
            StartIcon ? 'ps-10' : ''
          } ${EndIcon ? 'pe-10' : ''} p-2.5`}
          type={type || 'text'}
          autoFocus={autoFocus}
          placeholder={placeholder || ''}
          value={value}
          onChange={onChange}
          {...formik?.getFieldProps(name)}
          readOnly={readOnly}
        />
        {EndIcon && (
          <div class='absolute inset-y-0 end-0 flex items-center pe-3'>
            {EndIcon}
          </div>
        )}
      </div>
      {name && (
        <ErrorMessage
          name={name}
          component={'div'}
          className='text-xs text-red-500 font-light mt-1'
        />
      )}
    </div>
  )
}

export default InputFieldOutlined
