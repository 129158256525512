import React, { useState } from 'react'
import TopHeader from './top-header'
import NavItem from './nav-item'
import NavDropdown from './nav-dropdown'
import AppLogo from './app-logo'
import ChartIcon from 'icon/chart'
import SettingIcon from 'icon/setting'
import LocationIcon from 'icon/locationicon'
import WebhookIcon from 'icon/webhookicon'
import ScanIcon from 'icon/scanIcon'
import InfoIcon from 'icon/infoicon'
import IntegrationIcon from 'icon/integrationicon'
import BillIcon from 'icon/billicon'
import { useAgency } from 'context/agency'
import GraphIcon from 'icon/graph'
import KeyIcon2 from 'icon/key'
import SideNAvButtonGradient from 'component/Buttons/sidenav-btn'

const menu = [
  {
    label: 'Dashboard',
    path: '/dashboard',
    icon: <GraphIcon />,
  },
  {
    label: 'Sub-Accounts',
    path: '/location',
    icon: <LocationIcon />,
  },
  {
    label: 'Webhook',
    path: '/webhook',
    icon: <WebhookIcon />,
  },
]

const settings = [
  {
    label: 'Comapny Info',
    path: '/settings/info',
    icon: <InfoIcon />,
  },
  {
    label: 'ApiKey',
    path: '/settings/apikey',
    icon: <KeyIcon2 />,
  },
  {
    label: 'Integration',
    path: '/settings/integration',
    icon: <IntegrationIcon />,
  },
  {
    label: 'Billing',
    path: '/settings/billing',
    icon: <BillIcon />,
  },
]

function Sidebar() {
  const { premiumApps, styles } = useAgency()
  const [openSideMenu, setOpenSideMenu] = useState(false)
  const [showSettings, setShowSettings] = useState(
    window.location.pathname.includes('settings')
  )
  const sidenav_bg_color = '#0a4d85'
  const page_color = styles.page_bg_color
  const s_text_color = styles.sidenav_text_color

  const renderItem = (menu_item) => (
    <div key={menu_item.label}>
      {menu_item.children ? (
        <NavDropdown {...menu_item} />
      ) : (
        <NavItem
          {...menu_item}
          active={window.location.pathname == menu_item.path}
        />
      )}
    </div>
  )

  return (
    // <div>
    //   <nav
    //     // style={{ backgroundColor: page_color }}
    //     className='fixed top-0 z-50 w-full bg-white border-b border-gray-200 '
    //   >
    //     <div className='px-3 py-3 lg:px-5 lg:pl-3'>
    //       <div className='flex items-center justify-between'>
    //         <AppLogo onToggle={() => setOpenSideMenu(!openSideMenu)} />
    //         <TopHeader />
    //       </div>
    //     </div>
    //   </nav>
    //   <aside
    //     style={{
    //       backgroundColor: sidenav_bg_color,
    //     }}
    //     className={`fixed top-0 left-0 z-40 w-64 h-screen pt-20 transition-transform bg-white border-r border-gray-200 sm:translate-x-0  ${
    //       openSideMenu ? 'transform-none' : '-translate-x-full'
    //     }`}
    //   >
    //     <div
    //       style={{
    //         backgroundColor: sidenav_bg_color,
    //       }}
    //       className='px-3 pb-4 overflow-y-auto h-[calc(100vh-140px)] bg-white '
    //     >
    //       {showSettings && (
    //         <div>
    //           <SideNAvButtonGradient
    //             type='button'
    //             onClick={() => {
    //               setShowSettings(false)
    //             }}
    //             className={'w-full pb-2 mb-4'}
    //           >
    //             Go Back
    //           </SideNAvButtonGradient>
    //         </div>
    //       )}
    //       <ul className='space-y-2 font-medium'>
    //         {(showSettings ? settings : menu).map((menu_item) =>
    //           renderItem(menu_item)
    //         )}
    //       </ul>
    //       {!showSettings && (
    //         <div class='absolute bottom-0 w-full px-4 -ml-3 mb-3'>
    //           <SideNAvButtonGradient
    //             type='button'
    //             onClick={() => {
    //               setShowSettings(true)
    //             }}
    //             className={'w-full pb-2'}
    //           >
    //             Settings
    //           </SideNAvButtonGradient>
    //         </div>
    //       )}
    //     </div>
    //   </aside>
    // </div>
    <div className=''>
      <nav
        style={{ backgroundColor: page_color }}
        className='pt-3 fixed w-full md:w-[calc(100%-329px)] top-0 z-50 left-0 md:left-[292px] '
      >
        <div className=' bg-white border-b border-gray-200 rounded-t-3xl'>
          <div className='px-3 py-3 flex items-center justify-between md:justify-end'>
            <button
              type='button'
              className='inline-flex items-center p-2 text-sm text-gray-500 rounded-lg sm:hidden hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-gray-200'
              onClick={() => setOpenSideMenu(!openSideMenu)}
            >
              <span className='sr-only'>Open sidebar</span>
              <svg
                className='w-6 h-6'
                aria-hidden='true'
                fill='currentColor'
                viewBox='0 0 20 20'
                xmlns='http://www.w3.org/2000/svg'
              >
                <path
                  clipRule='evenodd'
                  fillRule='evenodd'
                  d='M2 4.75A.75.75 0 012.75 4h14.5a.75.75 0 010 1.5H2.75A.75.75 0 012 4.75zm0 10.5a.75.75 0 01.75-.75h7.5a.75.75 0 010 1.5h-7.5a.75.75 0 01-.75-.75zM2 10a.75.75 0 01.75-.75h14.5a.75.75 0 010 1.5H2.75A.75.75 0 012 10z'
                />
              </svg>
            </button>
            <TopHeader />
          </div>
        </div>
      </nav>
      <aside
        style={{
          backgroundColor: sidenav_bg_color,
        }}
        className={` rounded-3xl h-[calc(100vh-24px)] mt-3 fixed top-0 md:left-3 z-40 w-64   transition-transform bg-white border- sm:translate-x-0  ${
          openSideMenu ? 'transform-none' : '-translate-x-full'
        }`}
      >
        <div className='min-h-[67px] flex flex-col justify-center  mb-2'>
          <AppLogo onToggle={() => setOpenSideMenu(!openSideMenu)} />
        </div>

        <div
          style={{
            height: `calc(100vh - ${showSettings ? '67px' : '120px'})`,
          }}
          className={`px-3 pb-4 overflow-y-auto ${
            sidenav_bg_color ? '' : 'bg-white'
          } `}
        >
          {showSettings && (
            <div class='my-3'>
              <SideNAvButtonGradient
                type='button'
                onClick={() => {
                  setShowSettings(false)
                }}
                className={'w-full pb-2'}
              >
                Go Back
              </SideNAvButtonGradient>
            </div>
          )}
          <ul className='space-y-2 font-medium'>
            {(showSettings ? settings : menu).map((menu_item) =>
              renderItem(menu_item)
            )}
          </ul>
          {!showSettings && (
            <div class='absolute bottom-0 w-full px-4 -ml-3 mb-3'>
              <SideNAvButtonGradient
                type='button'
                onClick={() => {
                  setShowSettings(true)
                }}
                className={'w-full pb-2'}
              >
                Settings
              </SideNAvButtonGradient>
            </div>
          )}
        </div>
      </aside>
    </div>
  )
}

export default Sidebar
