import { useAgency } from 'context/agency'
import ProfileButton from './profile-button'
import Select from 'component/Dropdown/dropdown'
import Api from 'utils/api'
import { useLocationInfo } from 'context/location'
import env from 'configuration'
import { getAuth } from 'utils/auth-helper'
import { useAuth } from 'context/auth'

function TopHeader() {
  const { agency, locations } = useAgency()
  const { setCurrentUser } = useAuth()
  const { setLocation } = useLocationInfo()

  const handleSelectLocation = async (e) => {
    const { value } = e.target
    const location = await locations.find((loc) => loc._id === value)
    const { response } = await Api('/user', 'put', {
      payload: { selected_location: location.api_key },
      notify: true,
    })
    if (response) {
      setCurrentUser(response.data)
      setLocation(location)
      window.open(env.LOCATION_LEVEL + '?auth=' + getAuth().token, '_blank')
    }
  }

  return (
    <div className='flex items-center'>
      <div className='flex items-center '>
        <div class='mr-4'>
          <Select
            className='w-[160px]' // Set width to 150px for this instance
            onSelect={handleSelectLocation}
            placeholder={'Select Location'}
            value={agency.selected_location}
            options={locations.map((location) => ({
              label: location.name,
              value: location._id,
            }))}
          />
        </div>
        <div class='mr-2'></div>
        <div>
          <ProfileButton />
        </div>
      </div>
    </div>
  )
}

export default TopHeader
