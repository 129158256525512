import React, { lazy } from 'react'
import ComponentLoader from './component-loader'
import AdminLayout from 'screens/admin/AdminLayout'
import { Navigate } from 'react-router-dom'
// import Home from '../screens/admin/Home/home'

const Viewdashboard = ComponentLoader(
  lazy(() => import('screens/admin/Locations/view-dashboard'))
)
const Dashboard = ComponentLoader(
  lazy(() => import('screens/admin/Dashboard/dashboard'))
)
const Home = ComponentLoader(lazy(() => import('screens/admin/Home/home')))
const Location = ComponentLoader(
  lazy(() => import('screens/admin/Locations/locations'))
)

const ApiKey = ComponentLoader(
  lazy(() => import('screens/admin/ApiKey/apikey'))
)

//WEBHOOKS
const WebhookModule = ComponentLoader(
  lazy(() => import('screens/admin/Webhook/'))
)
const Webhook = ComponentLoader(
  lazy(() => import('screens/admin/Webhook/webhook-list'))
)
const WebhookLogs = ComponentLoader(
  lazy(() => import('screens/admin/Webhook/view-logs'))
)
const CreateWebhook = ComponentLoader(
  lazy(() => import('screens/admin/Webhook/create/create-webhook'))
)

const BasicInfo = ComponentLoader(
  lazy(() => import('screens/admin/BasicInfo/'))
)

const Billing = ComponentLoader(
  lazy(() => import('screens/admin/Bilings/billing'))
)

const Integration = ComponentLoader(
  lazy(() => import('screens/admin/Integration/integration'))
)

// INTEGRATIONS
const Associate = ComponentLoader(
  lazy(() => import('screens/public/integration/ghl/associate'))
)
const IntegrateStripe = ComponentLoader(
  lazy(() => import('screens/public/integration/stripe/stripe'))
)

// PROFILE
const Profile = ComponentLoader(
  lazy(() => import('screens/admin/Profile/profile'))
)

const AdminRoutes = () => {
  const routes = [
    {
      path: '',
      element: <AdminLayout />,
      children: [
        {
          path: 'dashboard',
          element: <Dashboard />,
        },

        {
          path: 'location',
          children: [
            {
              element: <Location />,
              path: '',
              index: true,
            },
            {
              path: 'view-dashboard',
              element: <Viewdashboard />,
            },
          ],
        },
        {
          path: 'webhook',
          element: <WebhookModule />,
          children: [
            {
              path: '',
              index: true,
              element: <Webhook />,
            },
            {
              path: 'create-webhook',
              element: <CreateWebhook />,
            },
            {
              path: 'update',
              element: <CreateWebhook />,
            },
            {
              path: 'view-logs',
              element: <WebhookLogs />,
            },
          ],
        },
        {
          path: 'profile',
          element: <Profile />,
        },
        {
          path: 'settings',
          children: [
            {
              path: 'info',
              element: <BasicInfo />,
            },
            {
              path: 'integration',
              element: <Integration />,
            },
            {
              path: 'billing',
              element: <Billing />,
            },
            {
              path: 'apikey',
              element: <ApiKey />,
            },
          ],
        },
        {
          path: '',
          index: true,
          element: <Navigate replace to={'/dashboard'} />,
        },
        {
          path: '*',
          index: true,
          element: <Navigate replace to={'/dashboard'} />,
        },
      ],
    },
    {
      path: 'home',
      element: <Home />,
    },
    {
      path: 'integrations',
      children: [
        {
          path: 'crm',
          children: [
            {
              path: 'associate',
              element: <Associate />,
            },
          ],
        },
        {
          path: 'stripe',
          element: <IntegrateStripe />,
        },
      ],
    },
    {
      path: '',
      element: <Navigate replace to={'/'} />,
    },
    {
      path: '*',
      element: <Navigate replace to={'/'} />,
    },
  ]

  return routes
}

export default AdminRoutes
