import DropdownIcon from 'icon/dropdown'
import { useEffect, useState } from 'react'
import NavItem from './nav-item'
import { useAgency } from 'context/agency'
import { useLocation } from 'react-router-dom'

function NavDropdown({ label, path, icon, children }) {
  const [toggleNav, setToggleNav] = useState(false)
  const { styles } = useAgency()
  const { pathname } = useLocation()
  const [active, setActive] = useState(false)
  const iconStyle = {
    transition: 'transform 0.3s ease', // Example transition property
    transform: toggleNav ? 'rotate(180deg)' : 'rotate(0)', // Rotate the icon when toggleNav is true
  }

  const sa_bg_color = ''
  const sa_text_color = ''
  const s_text_color = ''

  useEffect(() => {
    setActive(pathname == path)
  }, [path, pathname])

  return (
    <li>
      <button
        type='button'
        // class='flex items-center w-full p-2 text-base text-gray-500 transition duration-75 rounded-lg group hover:bg-gray-100'
        onClick={() => setToggleNav(!toggleNav)}
        onMouseEnter={(e) =>
          !active &&
          e.currentTarget.setAttribute(
            'style',
            `background-color: ${sa_bg_color}; color: ${sa_text_color}`
          )
        }
        onMouseLeave={(e) =>
          !active &&
          e.currentTarget.setAttribute(
            'style',
            `background-color: ''; color: ${s_text_color}`
          )
        }
        style={{
          backgroundColor: active ? sa_bg_color : '',
          color: active ? 'gray' : 'white',
        }}
        className={`flex items-center p-2  rounded-lg w-full transition duration-75
        ${active ? (sa_bg_color ? '' : 'bg-gray-100 ') : 'text-gray-500'}
        ${active ? (sa_text_color ? '' : ' text-gray-900') : 'text-gray-500'}
        ${sa_bg_color ? '' : 'hover:bg-gray-100'} group`}
      >
        {icon}
        <span class='flex-1 ms-3 text-left rtl:text-right whitespace-nowrap'>
          {label}
        </span>
        <DropdownIcon style={iconStyle} />
      </button>
      {toggleNav && (
        <ul id='dropdown-example' class='py-2 space-y-2 mx-3'>
          {children.map((item) => (
            <NavItem {...item} />
          ))}
        </ul>
      )}
    </li>
  )
}

export default NavDropdown
