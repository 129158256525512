import { ErrorMessage, useFormikContext } from 'formik'
import DropdownIcon from 'icon/dropdown'
import React from 'react'

export default function Select({
  name,
  placeholder,
  label,
  options = [],
  required,
  onSelect,
  value,
  className, // Add className prop here
}) {
  const formik = useFormikContext()
  const fieldProps = name ? formik.getFieldProps(name) : {}
  return (
    <>
      {label && (
        <label
          htmlFor='country'
          className='block mb-2 text-sm font-medium text-gray-900'
        >
          {required ? `${label} *` : label}
        </label>
      )}
      <div className='relative w-full'>
        <div className='absolute right-2 top-4'>
          <DropdownIcon />
        </div>
        <select
          value={value}
          onChange={onSelect}
          placeholder={placeholder || ''}
          {...fieldProps}
          className={`w-full truncate pr-5 p-2.5 text-sm text-gray-500 bg-white border rounded-md shadow-sm outline-none appearance-none focus:border-indigo-600 ${className}`} // Add className here
        >
          <option hidden selected value={''}>
            {placeholder}
          </option>
          {options.map((option) => (
            <option
              key={option.value}
              disabled={option.disabled}
              value={option.value}
            >
              {option.label}
            </option>
          ))}
        </select>
        {name && (
          <ErrorMessage
            name={name}
            component={'div'}
            className='text-xs text-red-500 font-light mt-1'
          />
        )}
      </div>
    </>
  )
}
