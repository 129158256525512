import React from 'react'
import SinglePageCustomMenuLinkLayout from 'screens/public/SinglePageCMLayout'



const PublicRoutes = [
  {
    path: '/',
    element: <SinglePageCustomMenuLinkLayout />,
    children: [
      
    ],
  },
  {
    path: 'location/:location_id/user/:email',
    element: (
      <div className='p-12'>
      </div>
    ),
  },
  
]

export default PublicRoutes
